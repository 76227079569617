import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "bootstrap"; // Bootstrap JS
import "@/assets/fonts/Avenir/font.css"; // APP Fonts css
import "bootstrap/dist/css/bootstrap.min.css"; // Bootstrap CSS
import PrimeVue from "primevue/config"; // PrimeVue
import "primevue/resources/primevue.css"; // PrimeVue CSS
import "primeicons/primeicons.css"; // PrimeIcons CSS
import "primevue/resources/themes/arya-blue/theme.css"; // PrimeVue Theme CSS


createApp(App).use(PrimeVue).use(router).use(store).mount("#app");
