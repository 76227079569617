<template>
  <Menubar :model="items" />
</template>

<script>
import Menubar from "primevue/menubar";
import { Analytics } from "aws-amplify";

export default {
  name: "NavbarComponent",
  components: {
    Menubar,
  },
  data() {
    return {
      items: [
        {
          label: "Home",
          icon: "",
          to: "/",
        },
        {
          label: "About Us",
          icon: "",
          to: "/about",
        },
        {
          label: "Publications",
          icon: "",
          to: "/publication",
        },
        {
          label: "CoreSlicer 1.0",
          url: "https://old.coreslicer.com",
          target: "_blank",
          command: () => {
            this.goToOldSite();
          },
          icon: "",
        },
        {
          label: "CoreSlicer 2.0",
          command: () => {
            this.login();
          },
          icon: "",
        },
      ],
    };
  },

  methods: {
    goToOldSite: function () {
      Analytics.record({
        name: "Coreslicer 1.0 Site Button Clicked",
      });
    },

    login: function () {
        this.$router.push("/");
        // wait for the page to load
        setTimeout(() => {
          document.querySelector(".cs-ending").scrollIntoView({
            behavior: "smooth",
          });
        }, 1000);
      Analytics.record({
        name: "Demo CoreSlicer 2.0 Button Clicked",
      });
    },
  },
};
</script>
