<template>
  <header class="cs-header">
    <div class="logo-container">
      <img class="cs-logo" src="@/assets/img/logo.png" />
    </div>

    <div class="nav-container">
      <NavbarComponent> </NavbarComponent>
    </div>
  </header>
  <router-view />

  <footer class="cs-footer">
    <div class="cs-footer-content">
      <img class="cs-logo-footer" src="@/assets/img/logo.png" />
      <a class="cs-contact-footer" href="mailto:info@coreslicer.com">
        Contact Us
      </a>
    </div>
  </footer>
</template>

<script>
import NavbarComponent from "@/components/navbar-component.vue";

export default {
  name: "App",
  components: {
    NavbarComponent,
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background-color: #1c1c1d;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.cs-header .p-menubar {
  background: transparent;
  border: none;
  font-family: "Avenir-Medium";
  font-size: 16px;
  font-weight: 800;
  color: white;
}

.cs-header
  .p-menubar
  .p-menuitem:not(p.highlight):not(p.disabled).p-focus
  > .p-menuitem-content {
  background: transparent;
}

@media screen and (max-width: 960px) {
  .cs-header .p-menubar.p-menubar-mobile-active .p-menubar-root-list {
    left: -160px;
  }

  .cs-header .p-menubar .p-menubar-root-list {
    width: 200px;
  }
}

.cs-header {
  padding-left: 0px;
  padding-right: 0px;
  max-width: 1440px;
  margin: 0 auto;
  height: 72px;
  width: 100%;
  background-color: #1c1c1d;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 60px;
  z-index: 1;
  position: relative;
}

.cs-header > * {
  z-index: 2;
}

.logo-container {
  display: flex;
  flex-direction: row;
}

.nav-container {
  display: flex;
  position: absolute;
  right: 0;
}

.cs-logo {
  object-fit: contain;
  height: 80px;
}

@media screen and (max-width: 1550px) {
  .cs-header {
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media screen and (max-width: 640px) {
  .cs-logo {
    height: 40px;
  }
}

@media screen and (max-width: 900px) {
  .cs-header {
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media screen and (max-width: 425px) {
  .cs-header {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.cs-footer {
  width: 100%;
  height: 372px;
  bottom: 0;
  margin: 0 auto;
  background-color: black;
  width: 100%;
  height: 372px;
  bottom: 0;
  display: flex;
  flex-direction: row;
  padding-top: 30px;
  padding-bottom: 30px;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.cs-footer-content {
  max-width: 1440px;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.cs-logo-footer {
  object-fit: contain;
  width: 300px;
  height: 70px;
  align-self: center;
  position: absolute;
  left: 0px;
}
.cs-contact-footer {
  align-self: center;
  width: 50%;
  text-align: right;
  position: absolute;
  right: 0px;
  text-decoration: underline;
  color: #77b3f0;
  font-family: "Avenir-Light";
  font-size: 20px;
  line-height: 25px;
  cursor: pointer;
  margin-right: 20px;
}
</style>
