<!-- eslint-disable prettier/prettier -->

<template>
  <div class="study-overlay-modal" id="study-overlay-modal">
    <div class="study-overlay-modal-exit-button" @click="hideStudy()">X</div>
    <img class="study-1" id="study-1" src="@/assets/img/study1hd.png" />
    <img class="study-2" id="study-2" src="@/assets/img/study2hd.png" />
    <img class="study-3" id="study-3" src="@/assets/img/study3hd.png" />
    <img class="study-4" id="study-4" src="@/assets/img/study4hd.png" />
    <img class="study-5" id="study-5" src="@/assets/img/study5hd.png" />
    <img class="study-6" id="study-6" src="@/assets/img/study6hd.png" />
  </div>

  <main>
    <div class="cs-hero-container">
      <div class="cs-hero-container-text-box">
        <div class="cs-hero-container-text-box-plus-sign-top-right">+</div>
        <div class="cs-hero-container-text-box-plus-sign-top-left">+</div>
        <div class="cs-hero-container-text-box-plus-sign-bottom-right">+</div>
        <div class="cs-hero-container-text-box-plus-sign-bottom-left">+</div>
        <div class="cs-hero-container-header">
          KNOW WHAT YOUR PATIENT IS MADE OF
        </div>
        <div class="cs-hero-container-text">
          AI imaging software for precise body analytics. Clinically validated
          and actionable, in 2 clicks.
        </div>
      </div>
      <img class="cs-ellipse-group-1" src="@/assets/img/ellipse-group-1.png" />
      <img class="cs-ellipse-group-2" src="@/assets/img/ellipse-group-2.png" />
      <video
        class="cs-hero-container-image"
        src="@/assets/img/hero.mp4"
        muted="muted"
        playsinline="true"
        webkit-playsinline="true"
      />
    </div>

    <div class="cs-steps">
      <div class="cs-steps-line-top-right"></div>
      <div class="cs-steps-line-top-left-1"></div>
      <div class="cs-steps-line-top-left-2"></div>
      <div class="cs-steps-line-bottom-right"></div>
      <div class="cs-steps-line-bottom-left-1"></div>
      <div class="cs-steps-line-bottom-left-2"></div>
      <div class="cs-steps-title">CASE STUDY</div>
      <div class="cs-steps-box">
        <div class="cs-steps-left">
          <div class="cs-steps-left-1">
            <div class="cs-steps-left-1-title">STEP 1</div>
            <div class="cs-steps-left-1-text">
              Load your patient's clinical CT or MR scan into CoreSlicer
            </div>

            <img class="cs-steps-left-1-image" src="@/assets/img/step1.png" />
          </div>
          <div class="cs-steps-left-3">
            <div class="cs-steps-left-3-title">STEP 3</div>
            <div class="cs-steps-left-3-text">
              View your patient's results, share with colleagues or post to your
              EHR
            </div>
            <img class="cs-steps-left-3-image" src="@/assets/img/step3.png" />
          </div>
        </div>
        <div class="cs-steps-box-centered-step-circles">
          <div class="cs-steps-1">1</div>
          <div class="cs-steps-line-1"></div>
          <div class="cs-steps-2">2</div>
          <div class="cs-steps-line-2"></div>
          <div class="cs-steps-3">3</div>
          <div class="cs-steps-line-3"></div>
          <div class="cs-steps-4">4</div>
        </div>
        <div class="cs-steps-right">
          <div class="cs-steps-right-2">
            <div class="cs-steps-right-2-title">STEP 2</div>
            <div class="cs-steps-right-2-text">
              Choose one of our AI models to automatically analyze the scan
            </div>
            <img class="cs-steps-right-2-image" src="@/assets/img/step2.png" />
          </div>
          <div class="cs-steps-right-4">
            <div class="cs-steps-right-4-title">STEP 4</div>
            <div class="cs-steps-right-4-text">
              Optionally, review and edit the measurements
            </div>
            <img class="cs-steps-right-4-image" src="@/assets/img/step4.png" />
          </div>
        </div>
        <div class="cs-steps-mobile">
          <div class="cs-steps-left-1">
            <div class="cs-steps-left-1-title">STEP 1</div>
            <div class="cs-steps-left-1-text">
              Load your patient's clinical imaging test into Coreslicer
            </div>

            <img class="cs-steps-left-1-image" src="@/assets/img/step1.png" />
          </div>
          <div class="cs-steps-right-2">
            <div class="cs-steps-right-2-title">STEP 2</div>
            <div class="cs-steps-right-2-text">
              Choose one of our AI imaging analysis models to run
            </div>
            <img class="cs-steps-right-2-image" src="@/assets/img/step2.png" />
          </div>
          <div class="cs-steps-left-3">
            <div class="cs-steps-left-3-title">STEP 3</div>
            <div class="cs-steps-left-3-text">
              View your patient's results, share with colleagues or post to your
              EHR
            </div>
            <img class="cs-steps-left-3-image" src="@/assets/img/step3.png" />
          </div>
          <div class="cs-steps-right-4">
            <div class="cs-steps-right-4-title">STEP 4</div>
            <div class="cs-steps-right-4-text">
              Optionally, review and edit the measurements
            </div>
            <img class="cs-steps-right-4-image" src="@/assets/img/step4.png" />
          </div>
        </div>
      </div>
    </div>

    <div class="cs-features">
      <div class="cs-features-line-bottom-right"></div>
      <div class="cs-features-line-bottom-left-1"></div>
      <div class="cs-features-line-bottom-left-2"></div>
      <div class="cs-features-title">ACTIONABLE INSIGHTS</div>
      <div class="cs-features-cards">
        <div class="cs-features-card-1">
          <div class="cs-features-card-1-title">FRAILTY ASSESSMENT</div>
          <div class="cs-features-card-1-text">
            Our frailty markers identify older patients who are at high risk of
            fatal or debilitating complications in various surgical,
            cardiovascular, oncologic, ICU and ER settings
          </div>
          <div class="cs-features-plus-sign-1">+</div>
        </div>

        <div class="cs-features-card-2">
          <div class="cs-features-card-2-title">DISEASE SCREENING</div>
          <div class="cs-features-card-2-text">
            Our calcification markers opportunistically screen for coronary
            disease, aortic stenosis, osteoporosis, to name a few; empowering
            clinicians to initiate preventative therapies
          </div>
          <div class="cs-features-plus-sign-2">+</div>
        </div>

        <div class="cs-features-card-3">
          <div class="cs-features-card-3-title">OBESITY PHENOTYPES</div>
          <div class="cs-features-card-3-text">
            Our adiposity markers shed light on novel subtypes of diabetes
            mellitus and other obesity-related diseases, opening the door for
            targeted therapeutic approaches
          </div>
          <div class="cs-features-plus-sign-3">+</div>
        </div>

        <div class="cs-features-card-4">
          <div class="cs-features-card-4-title">Precision dosing</div>
          <div class="cs-features-card-4-text">
            Our muscle markers and lean body weight measures - more so than
            total body weight - identify patients who are at high risk of
            adverse drug events secondary to overdosing
          </div>
        </div>
      </div>
    </div>

    <div class="cs-clinical">
      <div class="cs-clinical-line-middle"></div>
      <div class="cs-clinical-title">CLINICAL VALIDATION</div>
      <div class="cs-clinical-cards">
        <img src="@/assets/img/ellipse-group-3.png" class="ellipse-group-3" />
        <div class="cs-clinical-card-1" @click="displayStudy(1)">
          <img class="cs-clinical-card-1-image" src="@/assets/img/study1.png" />
          <div class="cs-clincal-card-text-content-container">
            <div class="cs-clinical-card-1-title">TAVR</div>
            <div class="cs-clinical-card-1-text">
              Psoas Muscle Area and All-Cause Mortality After Transcatheter
              Aortic Valve Replacement: The Montreal-Munich Study
            </div>
            <div class="cs-clincal-card-1-reference">
              Canadian Journal of Cardiology 2016; 32(2): 177-182
            </div>
          </div>
        </div>
        <div class="cs-clinical-card-2" @click="displayStudy(2)">
          <img class="cs-clinical-card-2-image" src="@/assets/img/study2.png" />
          <div class="cs-clincal-card-text-content-container">
            <div class="cs-clinical-card-2-title">CARDIAC SURGERY</div>
            <div class="cs-clinical-card-2-text">
              Psoas Muscle Area and Length of Stay in Older Adults Undergoing
              Cardiac Operations
            </div>
            <div class="cs-clincal-card-2-reference">
              Annals of Thoracic Surgery 2017; 103(5): 1498-1504
            </div>
          </div>
        </div>
        <div class="cs-clinical-card-3" @click="displayStudy(3)">
          <img class="cs-clinical-card-3-image" src="@/assets/img/study3.png" />
          <div class="cs-clincal-card-text-content-container">
            <div class="cs-clinical-card-3-title">HEART TRANSPLANTATION</div>
            <div class="cs-clinical-card-3-text">
              Muscle Mass and Mortality After Cardiac Transplantation
            </div>
            <div class="cs-clincal-card-3-reference">
              Transplantation 2018; 102(12): 2101-2107
            </div>
          </div>
        </div>
        <div class="cs-clinical-card-4" @click="displayStudy(4)">
          <img class="cs-clinical-card-4-image" src="@/assets/img/study4.png" />
          <div class="cs-clincal-card-text-content-container">
            <div class="cs-clinical-card-4-title">
              PERIPHERAL ARTERIAL DISEASE
            </div>
            <div class="cs-clinical-card-4-text">
              Psoas Muscle Area Predicts All-Cause Mortality After Endovascular
              and Open Aortic Aneurysm Repair
            </div>
            <div class="cs-clincal-card-4-reference">
              European Journal of Vascular and Endovascular Surgery 2016; 52(6):
              764-769
            </div>
          </div>
        </div>
        <div class="cs-clinical-card-5" @click="displayStudy(5)">
          <img class="cs-clinical-card-5-image" src="@/assets/img/study5.png" />
          <div class="cs-clincal-card-text-content-container">
            <div class="cs-clinical-card-5-title">
              ADULT CONGENITAL HEART DISEASE
            </div>
            <div class="cs-clinical-card-5-text">
              Sarcopenia in Fontan patients: a sign of frailty-associated
              premature ageing?
            </div>
            <div class="cs-clincal-card-5-reference">
              Cardiology in the Young 2021; 31(5): 696-698
            </div>
          </div>
        </div>
        <div class="cs-clinical-card-6" @click="displayStudy(6)">
          <img class="cs-clinical-card-6-image" src="@/assets/img/study6.png" />
          <div class="cs-clincal-card-text-content-container">
            <div class="cs-clinical-card-6-title">COVID-19</div>
            <div class="cs-clinical-card-6-text">
              Cardiovascular Calcium Scoring by Deep Learning is Predictive of
              Mortality in Emergency Depeartment Patients
            </div>
            <div class="cs-clincal-card-6-reference">
              Circulation 2022; 146(S1): A14708
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="cs-aws">
      <div class="cs-aws-text">
        &ldquo; AWS selects <a class="cs-aws-text-blue">CoreSlicer</a> among the
        first partners for HealthLake Imaging &rdquo;
      </div>
      <img class="cs-aws-logo" src="@/assets/img/aws.png" />
    </div>

    <div class="cs-ending">
      <div class="cs-ending-container">
        <img src="@/assets/img/hero2.png" class="cs-ending-hero" />
        <div class="cs-ending-box">
          <div class="cs-ending-text">
            Your patient deserves better than one-size-fits-all medicine
          </div>
          <div class="cs-ending-text-small">
            Try the beta before it's publicly available
          </div>
          <input
            class="cs-ending-input"
            placeholder="example@email.com"
            id="cs-ending-input"
          />
          <div class="cs-ending-checkbox-and-text">
            <input
              type="checkbox"
              class="cs-ending-checkbox"
              id="cs-ending-checkbox"
            />
            <div class="cs-ending-checkbox-text">
              I agree to be included in the monthly newsletter
            </div>
          </div>
          <Button class="cs-ending-button" @click="subscribeUser()"
            >Join the waitlist</Button
          >
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import Button from "primevue/button";

export default {
  name: "HomeView",
  components: {
    Button,
  },

  data() {
    return {
      studyMapping: {
        1: "TAVR",
        2: "Cardic Surgery",
        3: "Heart Transplantation",
        4: "Peripheral Arterial Disease",
        5: "Adult Congenital Heart Disease",
        6: "COVID-19",
      },
    };
  },

  mounted() {
    this.$nextTick(() => {
      // simulate user interaction first
      document.getElementsByClassName("cs-hero-container-image")[0].play();
    });
  },

  methods: {
    displayStudy: function (id) {
      let modal = document.getElementById("study-overlay-modal");
      let study = document.getElementById("study-" + id);
      modal.style.display = "flex";
      study.style.display = "block";
    },
    hideStudy: function () {
      let modal = document.getElementById("study-overlay-modal");
      let study1 = document.getElementById("study-1");
      let study2 = document.getElementById("study-2");
      let study3 = document.getElementById("study-3");
      let study4 = document.getElementById("study-4");
      let study5 = document.getElementById("study-5");
      let study6 = document.getElementById("study-6");
      modal.style.display = "none";
      study1.style.display = "none";
      study2.style.display = "none";
      study3.style.display = "none";
      study4.style.display = "none";
      study5.style.display = "none";
      study6.style.display = "none";
    },
    subscribeUser: async function () {
      // email, ip, location, newsletter
      let email = document.getElementById("cs-ending-input").value;
      let newsletter = document.getElementById("cs-ending-checkbox").checked;
      let city, region, country, ip, location;
      try {
        ip = await fetch("https://api.ipify.org/?format=json")
          .then((response) => response.json())
          .then((data) => data.ip);
      } catch (error) {
        ip = "null";
      }
      try {
        location = await fetch("https://ipapi.co/" + ip + "/json/").then(
          (response) => response.json()
        );
        city = location.city;
        region = location.region;
        country = location.country_name;
      } catch (error) {
        city = "null";
        region = "null";
        country = "null";
      }

      if (email != "") {
        try {
          const response = fetch(
            `${window.location.origin}/api/subscribe`, {
              method: 'POST',
              headers: {'Content-Type':'application/json'},
              body: JSON.stringify({
                email: email,
                newsletter: newsletter,
                ip: ip,
                city: city,
                region: region,
                country: country,
              })}).then(response => console.log(JSON.stringify(response)));
          console.log(response);
          // update the input box to say "Thanks for subscribing!"
          document.getElementById("cs-ending-input").value =
            "Thanks for subscribing!";
        } catch (error) {
          console.log(error);
        }
      }
    },
  },
};
</script>

<style scoped>
main {
  padding-left: 0px;
  padding-right: 0px;
  max-width: 1440px;
  margin: 0 auto;
}

html,
body {
  height: 100%;
}
.study-overlay-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 100;
  display: none;
  justify-content: center;
  align-items: center;
}

.study-overlay-modal-exit-button {
  position: absolute;
  top: 0;
  right: 20px;
  width: 50px;
  height: 50px;
  font-size: 40px;
  font-family: "Avenir-Light";
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: white;
}
/* center the study image in the middle of the modal*/
.study-1 {
  object-fit: contain;
  display: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  height: 80%;
  z-index: 100;
}

.study-2 {
  object-fit: contain;
  display: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  height: 80%;
  z-index: 100;
}

.study-3 {
  object-fit: contain;
  display: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  height: 80%;
  z-index: 100;
}

.study-4 {
  object-fit: contain;
  display: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  height: 80%;
  z-index: 100;
}
.study-5 {
  object-fit: contain;
  display: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  height: 80%;
  z-index: 100;
}
.study-6 {
  object-fit: contain;
  display: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  height: 80%;
  z-index: 100;
}

.cs-ellipse-group-1 {
  position: absolute;
  top: -200px;
  left: -200px;
  object-fit: contain;
  display: none;
}

.cs-hero-container {
  height: 800px;
  width: 100%;
  display: flex;
  position: relative;
  padding-top: 60px;
  padding-left: 30px;
  padding-right: 30px;
  margin-bottom: 20px;
}
.cs-hero-container-text-box {
  width: 713px;
  height: 285px;
  background: rgba(0, 0, 0, 0.4);
  position: relative;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-content: center;
  align-self: center;
  justify-content: center;
  z-index: 2;
}

@media screen and (max-width: 597px) {
  .cs-hero-container-text-box {
    height: 350px;
  }
}
@media screen and (max-width: 465px) {
  .cs-hero-container-text-box {
    height: 400px;
  }
}
@media screen and (max-width: 445px) {
  .cs-hero-container-text-box {
    height: fit-content;
  }
}
.cs-hero-container-text-box-plus-sign-top-right {
  position: absolute;
  top: -20px;
  right: -7px;
  font-family: "Avenir-Light";
  font-size: 25px;
  color: white;
}
.cs-hero-container-text-box-plus-sign-top-left {
  position: absolute;
  top: -20px;
  left: -8px;
  font-family: "Avenir-Light";
  font-size: 25px;
  color: white;
}
.cs-hero-container-text-box-plus-sign-bottom-right {
  position: absolute;
  bottom: -17px;
  right: -7px;
  font-family: "Avenir-Light";
  font-size: 25px;
  color: white;
}
.cs-hero-container-text-box-plus-sign-bottom-left {
  position: absolute;
  bottom: -17px;
  left: -8px;
  font-family: "Avenir-Light";
  font-size: 25px;
  color: white;
}
.cs-hero-container-header {
  font-family: "Avenir-Heavy";
  font-size: 31px;
  line-height: 42px;
  text-transform: uppercase;
  color: #ffffff;
  text-align: left;
  padding-bottom: 20px;
}
.cs-hero-container-text {
  font-family: "Avenir-Light";
  font-size: 30px;
  line-height: 39px;
  text-align: justify;
  color: #ffffff;
  text-align: left;
  padding-bottom: 20px;
}
.p-button.cs-hero-container-button {
  width: 111px;
  height: 37px;
  color: white;
  border-radius: 8px;
  background-color: #77b3f0;
  justify-content: center;
}
.cs-ellipse-group-2 {
  position: absolute;
  bottom: -220px;
  right: -125px;
  object-fit: contain;
  z-index: 1;
  display: none;
}
.cs-hero-container-image {
  position: absolute;
  right: 0px;
  background-color: #1c1c1d;
  clip-path: inset(1px 1px);
}

.cs-steps {
  width: 100%;
  height: fit-content;
  position: relative;
  display: flex;
  flex-direction: column;
}
.cs-steps-line-top-left-1 {
  width: 14px;
  border: 1px solid #ffffff;
  top: 20px;
  left: 20px;
  position: absolute;
}
.cs-steps-line-top-left-2 {
  width: 70px;
  border: 1px solid #ffffff;
  top: 20px;
  left: 30px;
  position: absolute;
  left: 50px;
}
.cs-steps-line-top-right {
  width: 45px;
  border: 1px solid #ffffff;
  position: absolute;
  top: 20px;
  right: 20px;
}
.cs-steps-line-bottom-right {
  width: 45px;
  border: 1px solid #ffffff;
  position: absolute;
  bottom: 20px;
  right: 20px;
}
.cs-steps-line-bottom-left-1 {
  width: 14px;
  border: 1px solid #ffffff;
  position: absolute;
  bottom: 20px;
  left: 20px;
}
.cs-steps-line-bottom-left-2 {
  width: 70px;
  border: 1px solid #ffffff;
  position: absolute;
  bottom: 20px;
  left: 50px;
}
.cs-steps-title {
  font-family: "Avenir-Heavy";
  font-size: 32px;
  line-height: 44px;
  display: flex;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  justify-content: center;
  color: #fae18f;
  margin-top: 81px;
  z-index: 2;
}
.cs-steps-box {
  display: flex;
  justify-content: center;
  padding-bottom: 162px;
  padding-left: 30px;
  padding-right: 30px;
}
.cs-steps-box-centered-step-circles {
  margin-top: 76px;
  height: 650px;
  width: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.cs-steps-1 {
  background: #cae0ab;
  width: 60px;
  height: 60px;
  font-family: "Avenir-Heavy";
  font-size: 32px;
  line-height: 44px;
  display: flex;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  border-radius: 50%;
  justify-content: center;
}
.cs-steps-line-1 {
  height: 139px;
  width: 1px;
  background: #cae0ab;
  align-self: center;
}
.cs-steps-2 {
  background: #c596d4;
  width: 60px;
  height: 60px;
  font-family: "Avenir-Heavy";
  font-size: 32px;
  line-height: 44px;
  display: flex;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  border-radius: 50%;
  justify-content: center;
}
.cs-steps-line-2 {
  height: 156px;
  width: 1px;
  background: #c596d4;
  align-self: center;
}
.cs-steps-3 {
  background: #fae18f;
  width: 60px;
  height: 60px;
  font-family: "Avenir-Heavy";
  font-size: 32px;
  line-height: 44px;
  display: flex;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  border-radius: 50%;
  justify-content: center;
}
.cs-steps-line-3 {
  height: 149px;
  width: 1px;
  background: #fae18f;
  align-self: center;
}
.cs-steps-4 {
  background: #77b3f0;
  width: 60px;
  height: 60px;
  font-family: "Avenir-Heavy";
  font-size: 32px;
  line-height: 44px;
  display: flex;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  border-radius: 50%;
  justify-content: center;
}

.cs-steps-left {
  margin-top: 70px;
  width: 40%;
}
.cs-steps-left-1 {
  display: flex;
  flex-direction: column;
}

.cs-steps-left-1-title {
  font-family: "Avenir-Heavy";
  font-size: 24px;
  line-height: 33px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  color: #cae0ab;
  padding-bottom: 4px;
  text-align: left;
}
.cs-steps-left-1-text {
  font-family: "Avenir-Light";
  font-size: 20px;
  line-height: 27px;
  display: flex;
  align-items: center;
  text-align: left;
  color: #ffffff;
  padding-bottom: 16px;
}
.cs-steps-left-1-image {
  border-radius: 8px;
  border-top: 16px solid #cae0ab;
  border-right: 16px solid #cae0ab;
  border-left: 16px solid #cae0ab;
}

.cs-steps-left-3 {
  display: flex;
  flex-direction: column;
  margin-top: 91px;
}
.cs-steps-left-3-title {
  font-family: "Avenir-Heavy";
  font-size: 24px;
  line-height: 33px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  color: #fae18f;
  padding-bottom: 4px;
  text-align: left;
}
.cs-steps-left-3-text {
  font-family: "Avenir-Light";
  font-size: 20px;
  line-height: 27px;
  display: flex;
  align-items: center;
  color: #ffffff;
  padding-bottom: 16px;
  text-align: left;
}
.cs-steps-left-3-image {
  border-radius: 8px;
  border-top: 16px solid #fae18f;
  border-right: 16px solid #fae18f;
  border-left: 16px solid #fae18f;
}

.cs-steps-right {
  margin-top: 280px;
  width: 40%;
}
.cs-steps-right-2 {
  display: flex;
  flex-direction: column;
}
.cs-steps-right-2-title {
  font-family: "Avenir-Heavy";
  font-size: 24px;
  line-height: 33px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  color: #c596d4;
  padding-bottom: 4px;
  text-align: left;
}
.cs-steps-right-2-text {
  font-family: "Avenir-Light";
  font-size: 20px;
  line-height: 27px;
  display: flex;
  align-items: center;
  text-align: left;
  color: #ffffff;
  padding-bottom: 16px;
}
.cs-steps-right-2-image {
  border-radius: 8px;
  border-top: 16px solid #c596d4;
  border-right: 16px solid #c596d4;
  border-left: 16px solid #c596d4;
}

.cs-steps-right-4 {
  display: flex;
  flex-direction: column;
  margin-top: 91px;
}
.cs-steps-right-4-title {
  font-family: "Avenir-Heavy";
  font-size: 24px;
  line-height: 33px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  color: #77b3f0;
  padding-bottom: 4px;
  text-align: left;
}
.cs-steps-right-4-text {
  font-family: "Avenir-Light";
  font-size: 20px;
  line-height: 27px;
  display: flex;
  align-items: center;
  text-align: left;
  color: #ffffff;
  padding-bottom: 16px;
}
.cs-steps-right-4-image {
  border-radius: 8px;
  border-top: 16px solid #77b3f0;
  border-right: 16px solid #77b3f0;
  border-left: 16px solid #77b3f0;
}

.cs-steps-mobile {
  display: none;
  flex-direction: column;
}

/* if the screen is less than 1510px in width, hide .cs-steps-left and .cs-steps-right and
cs-steps-box-centered-step-circles, and display cs-steps-mobile */

@media screen and (max-width: 1510px) {
  .cs-steps-left {
    display: none;
  }
  .cs-steps-right {
    display: none;
  }
  .cs-steps-box-centered-step-circles {
    display: none;
  }
  .cs-steps-mobile {
    display: flex;
    width: 100%;
  }
  .cs-steps-left-1 {
    margin-top: 50px;
  }
  .cs-steps-right-2 {
    margin-top: 50px;
  }
  .cs-steps-left-3 {
    margin-top: 50px;
  }
  .cs-steps-right-4 {
    margin-top: 50px;
  }
}

/* if the screen is less than 760px, start make .cs-steps-mobile width 100%
and remove padding */

@media screen and (max-width: 760px) {
  .cs-steps-mobile {
    width: 100%;
  }
}

.cs-features {
  width: 100%;
  height: fit-content;
  position: relative;
  display: flex;
  flex-direction: column;
  padding-left: 30px;
  padding-right: 30px;
}
.cs-features-line-bottom-right {
  width: 45px;
  border: 1px solid #ffffff;
  position: absolute;
  bottom: 20px;
  right: 20px;
}
.cs-features-line-bottom-left-1 {
  width: 14px;
  border: 1px solid #ffffff;
  position: absolute;
  bottom: 20px;
  left: 20px;
}
.cs-features-line-bottom-left-2 {
  width: 70px;
  border: 1px solid #ffffff;
  position: absolute;
  bottom: 20px;
  left: 50px;
}
.cs-features-title {
  font-family: "Avenir-Heavy";
  font-size: 32px;
  line-height: 44px;
  display: flex;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  justify-content: center;
  color: #fae18f;
  margin-top: 81px;
}
.cs-features-cards {
  /* the cards should be a grid that can hold a max of 4 cards */
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 64px;
  height: fit-content;
  margin-top: 50px;
  margin-bottom: 120px;
}

/* if the screen gets smaller than 1370px, make the card grid a row instead */

@media screen and (max-width: 1470px) {
  .cs-features-cards {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-gap: 64px;
    height: fit-content;
    margin-top: 50px;
    margin-bottom: 120px;
  }
  .cs-features-plus-sign-1 {
    display: none;
  }
  .cs-features-plus-sign-2 {
    display: none;
  }
  .cs-features-plus-sign-3 {
    display: none;
  }
}
@media screen and (max-width: 900px) {
  .cs-features-cards {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 64px;
    height: fit-content;
    margin-top: 50px;
    margin-bottom: 120px;
  }
  .cs-features-plus-sign-1 {
    display: none;
  }
  .cs-features-plus-sign-2 {
    display: none;
  }
  .cs-features-plus-sign-3 {
    display: none;
  }
}

@media screen and (max-width: 760px) {
  .cs-features-cards {
  }
  .cs-features-plus-sign-1 {
    display: none;
  }
  .cs-features-plus-sign-2 {
    display: none;
  }
  .cs-features-plus-sign-3 {
    display: none;
  }
}

@media screen and (max-width: 425px) {
  main {
    padding-left: 10px;
    padding-right: 10px;
  }
}
.cs-features-card-1 {
  background: linear-gradient(
      180deg,
      rgba(119, 179, 240, 0.4) 0%,
      rgba(255, 245, 0, 0.4) 51.04%,
      rgba(197, 150, 212, 0.4) 100%
    ),
    #000000;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 48px 20px;
  gap: 8px;
  position: relative;
}
.cs-features-card-1-title {
  font-family: "Avenir-Heavy";
  font-size: 24px;
  line-height: 24px;
  text-transform: uppercase;
  color: #ffffff;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  text-align: left;
}
.cs-features-card-1-text {
  font-family: "Avenir-Light";
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  text-align: left;
}
.cs-features-card-2 {
  background: linear-gradient(
      180deg,
      rgba(119, 179, 240, 0.4) 0%,
      rgba(255, 245, 0, 0.4) 51.04%,
      rgba(197, 150, 212, 0.4) 100%
    ),
    #000000;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 48px 20px;
  gap: 8px;
  position: relative;
}
.cs-features-card-2-title {
  font-family: "Avenir-Heavy";
  font-size: 24px;
  line-height: 24px;
  text-transform: uppercase;
  color: #ffffff;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  text-align: left;
}
.cs-features-card-2-text {
  font-family: "Avenir-Light";
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  text-align: left;
}
.cs-features-card-3 {
  background: linear-gradient(
      180deg,
      rgba(119, 179, 240, 0.4) 0%,
      rgba(255, 245, 0, 0.4) 51.04%,
      rgba(197, 150, 212, 0.4) 100%
    ),
    #000000;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 48px 20px;
  gap: 8px;
  position: relative;
}
.cs-features-card-3-title {
  font-family: "Avenir-Heavy";
  font-size: 24px;
  line-height: 24px;
  text-transform: uppercase;
  color: #ffffff;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  text-align: left;
}
.cs-features-card-3-text {
  font-family: "Avenir-Light";
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  text-align: left;
}
.cs-features-card-4 {
  background: linear-gradient(
      180deg,
      rgba(119, 179, 240, 0.4) 0%,
      rgba(255, 245, 0, 0.4) 51.04%,
      rgba(197, 150, 212, 0.4) 100%
    ),
    #000000;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 48px 20px;
  gap: 8px;
}
.cs-features-card-4-title {
  font-family: "Avenir-Heavy";
  font-size: 24px;
  line-height: 24px;
  text-transform: uppercase;
  color: #ffffff;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  text-align: left;
}
.cs-features-card-4-text {
  font-family: "Avenir-Light";
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  text-align: left;
}

.cs-features-plus-sign-1 {
  font-family: "Avenir-Light";
  font-size: 25px;
  color: white;
  position: absolute;
  bottom: -11px;
  right: -40px;
}
.cs-features-plus-sign-2 {
  font-family: "Avenir-Light";
  font-size: 25px;
  color: white;
  position: absolute;
  bottom: -11px;
  right: -40px;
}
.cs-features-plus-sign-3 {
  font-family: "Avenir-Light";
  font-size: 25px;
  color: white;
  position: absolute;
  bottom: -11px;
  right: -40px;
}

.cs-clinical {
  width: 100%;
  height: fit-content;
  position: relative;
  display: flex;
  flex-direction: column;
  padding-left: 30px;
  padding-right: 30px;
}
.cs-clinical-line-middle {
  width: 345px;
  border: 1px solid #ffffff;
  position: absolute;
  bottom: 0px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
}
.cs-clinical-title {
  font-family: "Avenir-Heavy";
  font-size: 32px;
  line-height: 44px;
  display: flex;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  justify-content: center;
  color: #fae18f;
  margin-top: 81px;
}
.cs-clinical-cards {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(3, 1fr);
  grid-gap: 64px;
  height: fit-content;
  margin-top: 72px;
  margin-bottom: 151px;
  position: relative;
}
.ellipse-group-3 {
  position: absolute;
  top: 20px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  object-fit: contain;
}

/* if the screen width is less than 1370px, change the grid*/

@media screen and (max-width: 1370px) {
  .cs-clinical-cards {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 64px;
    height: fit-content;
    margin-top: 72px;
    margin-bottom: 151px;
  }
}
.cs-clincal-card-text-content-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
}
.cs-clinical-card-1 {
  background: rgba(255, 255, 255, 0.12);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 12px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 24px;
  gap: 24px;
  position: relative;
  cursor: pointer;
  z-index: 2;
}
.cs-clinical-card-1:hover {
  background: rgba(255, 255, 255, 0.24);
  border: 1px solid rgba(255, 255, 255, 0.8);
  border-radius: 12px;
}
.cs-clinical-card-1-title {
  font-family: "Avenir-Heavy";
  font-size: 24px;
  line-height: 24px;
  text-transform: uppercase;
  color: #ffffff;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  text-align: left;
  margin-bottom: 8px;
}
.cs-clinical-card-1-text {
  font-family: "Avenir-Light";
  font-size: 18px;
  line-height: 24px;
  color: #ffffff;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  text-align: left;
}

.cs-clinical-card-2 {
  background: rgba(255, 255, 255, 0.12);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 12px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 24px;
  gap: 24px;
  position: relative;
  cursor: pointer;
}
.cs-clinical-card-2:hover {
  background: rgba(255, 255, 255, 0.24);
  border: 1px solid rgba(255, 255, 255, 0.8);
  border-radius: 12px;
}
.cs-clinical-card-2-title {
  font-family: "Avenir-Heavy";
  font-size: 24px;
  line-height: 24px;
  text-transform: uppercase;
  color: #ffffff;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  text-align: left;
  margin-bottom: 8px;
}
.cs-clinical-card-2-text {
  font-family: "Avenir-Light";
  font-size: 18px;
  line-height: 24px;
  color: #ffffff;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  text-align: left;
}

.cs-clinical-card-3 {
  background: rgba(255, 255, 255, 0.12);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 12px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 24px;
  gap: 24px;
  position: relative;
  cursor: pointer;
}
.cs-clinical-card-3:hover {
  background: rgba(255, 255, 255, 0.24);
  border: 1px solid rgba(255, 255, 255, 0.8);
  border-radius: 12px;
}
.cs-clinical-card-3-title {
  font-family: "Avenir-Heavy";
  font-size: 24px;
  line-height: 24px;
  text-transform: uppercase;
  color: #ffffff;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  text-align: left;
  margin-bottom: 8px;
}
.cs-clinical-card-3-text {
  font-family: "Avenir-Light";
  font-size: 18px;
  line-height: 24px;
  color: #ffffff;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  text-align: left;
}

.cs-clinical-card-4 {
  background: rgba(255, 255, 255, 0.12);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 12px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 24px;
  gap: 24px;
  position: relative;
  cursor: pointer;
}
.cs-clinical-card-4:hover {
  background: rgba(255, 255, 255, 0.24);
  border: 1px solid rgba(255, 255, 255, 0.8);
  border-radius: 12px;
}
.cs-clinical-card-4-title {
  font-family: "Avenir-Heavy";
  font-size: 24px;
  line-height: 24px;
  text-transform: uppercase;
  color: #ffffff;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  text-align: left;
  margin-bottom: 8px;
}
.cs-clinical-card-4-text {
  font-family: "Avenir-Light";
  font-size: 18px;
  line-height: 24px;
  color: #ffffff;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  text-align: left;
}

.cs-clinical-card-5 {
  background: rgba(255, 255, 255, 0.12);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 12px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 24px;
  gap: 24px;
  position: relative;
  cursor: pointer;
  z-index: 2;
}
.cs-clinical-card-5:hover {
  background: rgba(255, 255, 255, 0.24);
  border: 1px solid rgba(255, 255, 255, 0.8);
  border-radius: 12px;
}
.cs-clinical-card-5-title {
  font-family: "Avenir-Heavy";
  font-size: 24px;
  line-height: 24px;
  text-transform: uppercase;
  color: #ffffff;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  text-align: left;
  margin-bottom: 8px;
}
.cs-clinical-card-5-text {
  font-family: "Avenir-Light";
  font-size: 18px;
  line-height: 24px;
  color: #ffffff;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  text-align: left;
}

.cs-clinical-card-6 {
  background: rgba(255, 255, 255, 0.12);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 12px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 24px;
  gap: 24px;
  position: relative;
  cursor: pointer;
  z-index: 2;
}
.cs-clinical-card-6:hover {
  background: rgba(255, 255, 255, 0.24);
  border: 1px solid rgba(255, 255, 255, 0.8);
  border-radius: 12px;
}
.cs-clinical-card-6-title {
  font-family: "Avenir-Heavy";
  font-size: 24px;
  line-height: 24px;
  text-transform: uppercase;
  color: #ffffff;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  text-align: left;
  margin-bottom: 8px;
}
.cs-clinical-card-6-text {
  font-family: "Avenir-Light";
  font-size: 18px;
  line-height: 24px;
  color: #ffffff;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  text-align: left;
}

@media screen and (max-width: 665px) {
  .cs-clinical-card-1 {
    height: 260px;
  }
  .cs-clinical-card-2 {
    height: 260px;
  }
  .cs-clinical-card-3 {
    height: 260px;
  }
  .cs-clinical-card-4 {
    height: 260px;
  }
  .cs-clinical-card-5 {
    height: 260px;
  }
  .cs-clinical-card-6 {
    height: 260px;
  }
}

.cs-clincal-card-1-reference {
  font-family: "Avenir-Light";
  font-size: 15px;
  line-height: 24px;
  color: #ffffff;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  text-align: left;
  opacity: 0.5;
  margin-top: 10px;
  position: absolute;
  bottom: 0px;
}
.cs-clincal-card-2-reference {
  font-family: "Avenir-Light";
  font-size: 15px;
  line-height: 24px;
  color: #ffffff;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  text-align: left;
  opacity: 0.5;
  margin-top: 10px;
  position: absolute;
  bottom: 0px;
}
.cs-clincal-card-3-reference {
  font-family: "Avenir-Light";
  font-size: 15px;
  line-height: 24px;
  color: #ffffff;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  text-align: left;
  opacity: 0.5;
  margin-top: 10px;
  position: absolute;
  bottom: 0px;
}
.cs-clincal-card-4-reference {
  font-family: "Avenir-Light";
  font-size: 15px;
  line-height: 24px;
  color: #ffffff;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  text-align: left;
  opacity: 0.5;
  margin-top: 10px;
  position: absolute;
  bottom: 0px;
}
.cs-clincal-card-5-reference {
  font-family: "Avenir-Light";
  font-size: 15px;
  line-height: 24px;
  color: #ffffff;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  text-align: left;
  opacity: 0.5;
  margin-top: 10px;
  position: absolute;
  bottom: 0px;
}
.cs-clincal-card-6-reference {
  font-family: "Avenir-Light";
  font-size: 15px;
  line-height: 24px;
  color: #ffffff;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  text-align: left;
  opacity: 0.5;
  margin-top: 10px;
  position: absolute;
  bottom: 0px;
}

/* if the screen width is less than 570px, reduce the cs-clincal-card-text and title sizes */
@media screen and (max-width: 573px) {
}

/* if the screen width is less than 520px, reduce the cs-clincal-card-text and title sizes */

@media screen and (max-width: 520px) {
  .cs-clinical-card-1-title {
    font-size: 16px;
    line-height: 16px;
  }
  .cs-clinical-card-1-text {
    font-size: 12px;
    line-height: 16px;
  }
  .cs-clinical-card-2-title {
    font-size: 16px;
    line-height: 16px;
  }
  .cs-clinical-card-2-text {
    font-size: 12px;
    line-height: 16px;
  }
  .cs-clinical-card-3-title {
    font-size: 16px;
    line-height: 16px;
  }
  .cs-clinical-card-3-text {
    font-size: 12px;
    line-height: 16px;
  }
  .cs-clinical-card-4-title {
    font-size: 16px;
    line-height: 16px;
  }
  .cs-clinical-card-4-text {
    font-size: 12px;
    line-height: 16px;
  }
  .cs-clinical-card-5-text {
    font-size: 12px;
    line-height: 16px;
  }
  .cs-clinical-card-6-text {
    font-size: 12px;
    line-height: 16px;
  }
  .cs-clinical-card-5-title {
    font-size: 16px;
    line-height: 16px;
  }
  .cs-clinical-card-6-title {
    font-size: 16px;
    line-height: 16px;
  }
  .cs-clincal-card-1-reference {
    font-size: 10px;
    line-height: 16px;
  }
  .cs-clincal-card-2-reference {
    font-size: 10px;
    line-height: 16px;
  }
  .cs-clincal-card-3-reference {
    font-size: 10px;
    line-height: 16px;
  }
  .cs-clincal-card-4-reference {
    font-size: 10px;
    line-height: 16px;
  }
  .cs-clincal-card-5-reference {
    font-size: 10px;
    line-height: 16px;
  }
  .cs-clincal-card-6-reference {
    font-size: 10px;
    line-height: 16px;
  }
}

/* if the screen width is less than 456px, reduce the cs-clincal-card-text and title sizes */

@media screen and (max-width: 456px) {
  .cs-clinical-card-1-image {
    width: 120px;
    height: 150px;
  }
  .cs-clinical-card-2-image {
    width: 120px;
    height: 150px;
  }
  .cs-clinical-card-3-image {
    width: 120px;
    height: 150px;
  }
  .cs-clinical-card-4-image {
    width: 120px;
    height: 150px;
  }
  .cs-clinical-card-5-image {
    width: 120px;
    height: 150px;
  }
  .cs-clinical-card-6-image {
    width: 120px;
    height: 150px;
  }

  .cs-clinical-card-1 {
    height: 210px;
  }
  .cs-clinical-card-2 {
    height: 210px;
  }
  .cs-clinical-card-3 {
    height: 210px;
  }
  .cs-clinical-card-4 {
    height: 210px;
  }
  .cs-clinical-card-5 {
    height: 210px;
  }
  .cs-clinical-card-6 {
    height: 210px;
  }
}

/*last option is reduce grid gap */

@media screen and (max-width: 417px) {
  .cs-clinical-card-1 {
    gap: 16px;
  }
  .cs-clinical-card-2 {
    gap: 16px;
  }
  .cs-clinical-card-3 {
    gap: 16px;
  }
  .cs-clinical-card-4 {
    gap: 16px;
  }
  .cs-clinical-card-5 {
    gap: 16px;
  }
  .cs-clinical-card-6 {
    gap: 16px;
  }
}

/* last resort at 415px, remove padding from cs-clinical */

@media screen and (max-width: 415px) {
  .cs-clinical {
    padding: 0px;
  }
}

.cs-aws {
  margin-top: 135px;
  width: 100%;
  height: -moz-fit-content;
  height: fit-content;
  position: relative;
  display: flex;
  flex-direction: column;
  padding-left: 30px;
  padding-right: 30px;
  justify-content: center;
  align-items: center;
}

.cs-aws-text {
  font-family: "Avenir-Heavy";
  font-size: 48px;
  line-height: 66px;
  align-items: center;
  text-align: center;
  color: white;
}

.cs-aws-text-blue {
  color: #77b3f0 !important;
}
.cs-aws-logo {
  object-fit: content;
  width: 122px;
  height: 70px;
  margin-top: 40px;
}

.cs-ending {
  width: 100%;
  background: rgba(119, 179, 240, 0.1);
  margin-top: 147px;
  margin-bottom: 250px;
  height: 700px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* give the container a slight slope */
  transform: skewY(2deg);
}
.cs-ending-container {
  background: linear-gradient(
      180deg,
      rgba(119, 179, 240, 0.4) 0%,
      rgba(255, 245, 0, 0.4) 51.04%,
      rgba(197, 150, 212, 0.4) 100%
    ),
    #000000;
  border: 1px solid rgba(255, 255, 255, 0.1);
  display: flex;
  width: 100%;
  height: 650px;
}
.cs-ending-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 24px;
  margin-top: 50px;
  gap: 24px;
  width: 50%;
  transform: skewY(-2deg);
}
.cs-ending-text {
  font-family: "Avenir-Heavy";
  font-size: 38px;
  line-height: 52px;
  display: flex;
  align-items: center;
  color: #fae18f;
  text-align: left;
  align-self: flex-start;
}
.p-button.cs-ending-button {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  width: 161px;
  height: 54px;
  color: white;
  align-content: center;
  display: flex;
  justify-content: center;
  align-self: flex-start;
}
.p-button.cs-ending-button:hover {
  color: white !important;
}
.cs-ending-hero {
  mix-blend-mode: normal;
  width: 50%;
  height: 100%;
  object-fit: contain;
  opacity: 0.8;
  background-blend-mode: screen;
}

.cs-ending-text-small {
  font-family: "Avenir-Light";
  font-size: 20px;
  line-height: 27px;
  color: #ffffff;
  text-align: left;
  display: flex;
  width: 100%;
}
.cs-ending-input {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10.5px;
  gap: 14px;
  max-width: 544px;
  width: 100%;
  height: 63px;
  border: 1px solid #ced4da;
  border-radius: 6px;
  background: #ffffff;
  align-self: flex-start;
}
input,
select,
textarea {
  color: black;
}
textarea:focus,
input:focus {
  color: black;
}
.cs-ending-input::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: black;
  opacity: 0.8; /* Firefox */
}

.cs-ending-input:focus {
  box-shadow: 0px 0px 0px 2.8px #bfdbfe;
}

.cs-ending-checkbox-and-text {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: flex-start;
}
.cs-ending-checkbox {
  width: 22px;
  height: 22px;
  background: #ffffff;
  border-radius: 4px;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin-right: 10px;
}
.cs-ending-checkbox-text {
  font-family: "Avenir-Light";
  font-size: 16px;
  line-height: 22px;
  color: #ffffff;
  flex: none;
  order: 0;
  flex-grow: 1;
}

/* if the screen is less than 850px hide .cs-ending-hero, and give .cs-ending-box width 100%  */

@media screen and (max-width: 850px) {
  .cs-ending-hero {
    display: none;
  }
  .cs-ending-box {
    width: 100%;
  }
}
/* if the screen is less than 410px make font size 12px for cs-ending-checkbox-text  */

@media screen and (max-width: 430px) {
  .cs-ending-checkbox-text {
    font-size: 12px;
  }
}
</style>
